import React, { useState } from 'react'
import tw, { css, styled } from 'twin.macro'
import { Timeline, Tween, ScrollTrigger } from 'react-gsap'
import { breakpoints } from '../../../config/design-system'
import { Hero, WhyToApply } from '.'
import defaultVideo from '../../assets/creators/hero.mp4'
import advertisingVideo from '../../assets/creators/hero-advertising.mp4'
import entertainmentVideo from '../../assets/creators/hero-entertainment.mp4'

const sectionStyles = css`
  ${tw`w-screen h-screen flex relative`};
  &::after {
    content: '';
    ${tw`w-full absolute bottom-0`};
    height: 25%;
    background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 100) 100%);
  }
`

const innerSectionStyles = css`
  ${tw`grid grid-cols-12`};
`

const videoContainerStyles = css`
  ${tw`col-start-4 col-end-13 md:h-screen md:col-start-1 md:col-end-8 md:row-start-1 md:row-end-auto`};
`

const StyledVideo = styled.video`
  ${tw`max-h-screen md:w-full md:h-full object-cover`}
  min-height: 100%;
  max-width: unset;
  display: ${({ isDefault }) => (isDefault ? 'flex' : 'none')};
  @media (min-width: ${breakpoints.desktop}px) {
    width: 80%;
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
  }
`

const PinnedSections = ({ id }) => {
  const sectionId = 'pinned'

  const bgVideoTiggers = {
    default: defaultVideo,
    advertising: advertisingVideo,
    entertainment: entertainmentVideo
  }

  const defaultBgVideoStates = {
    default: true,
    advertising: false,
    entertainment: false
  }

  const [bgVideoStates, setBgVideoStates] = useState(defaultBgVideoStates)

  const handleSetBgVideo = (trigger) => {
    setBgVideoStates((bgVideoStates) =>
      Object.entries(bgVideoStates).reduce((acc, [key, value]) => {
        acc[key] = trigger === key
        return acc
      }, {})
    )
  }

  return (
    <section css={sectionStyles} id={sectionId}>
      <div css={innerSectionStyles} tw="h-full">
        <div css={videoContainerStyles}>
          {Object.keys(bgVideoTiggers).map((trigger) => (
            <StyledVideo
              key={trigger}
              isDefault={trigger === 'default'}
              visible={bgVideoStates[trigger]}
              loop
              autoPlay
              playsInline
              muted
            >
              <source type="video/mp4" src={bgVideoTiggers[trigger]} />
            </StyledVideo>
          ))}
        </div>
      </div>
      <ScrollTrigger pin trigger={`#${sectionId}`} start="top top" scrub>
        <Timeline>
          <Tween from={{ y: '0' }} to={{ y: '-100%' }}>
            <div tw="h-full w-full absolute z-10">
              <Hero
                handleSetBgVideo={handleSetBgVideo}
                bgVideoTiggers={Object.keys(bgVideoTiggers)}
              />
              <WhyToApply id={id} />
            </div>
          </Tween>
        </Timeline>
      </ScrollTrigger>
    </section>
  )
}

export { PinnedSections }
