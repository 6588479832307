import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import tw, { css } from 'twin.macro'
import Slider from '../Slider'
import FeaturedCreatorTemplate from '../../templates/FeaturedCreatorTemplate'
import useBreakpoint from 'use-breakpoint'
import { breakpoints } from '../../../config/design-system'

const GET_FEATURED_CREATOR = graphql`
  query FeaturedCreatorQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "featured-creator" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            location
            position
            row
            awards {
              awardsname
              awardsimage {
                id
                publicURL
              }
              awardscount
            }
            image {
              publicURL
            }
          }
        }
      }
    }
  }
`

const StyledSection = css`
  ${tw`flex flex-wrap mb-40 p-0 lg:h-full`};
`

const featuredCreatorAwardImg = css`
  max-width: 3rem;
`

const FeaturedCreators = ({ id }) => {
  const data = useStaticQuery(GET_FEATURED_CREATOR)
  const {
    allMarkdownRemark: { edges: featuredCreators }
  } = data || {}

  const { breakpoint } = useBreakpoint(breakpoints)
  const currentBreakpoint = breakpoints[breakpoint] || 0
  const SPACE_BETWEEN = currentBreakpoint <= 640 ? 20 : 80

  const filterCreatorsByRow = (row) =>
    featuredCreators.filter(({ node: { frontmatter } }) => frontmatter.row === row)

  const creatorsTopRow = filterCreatorsByRow('toprow')
  const creatorsBottomRow = filterCreatorsByRow('bottomrow')

  const swiperSettings = (row) => {
    return {
      autoplay: {
        delay: 1,
        pauseOnMouseEnter: false,
        disableOnInteraction: false,
        reverseDirection: row === 'toprow' ? false : true
      },
      centeredSlides: true,
      speed: 4000,
      loop: true,
      loopedSlides: row === 'toprow' ? creatorsTopRow.length : creatorsBottomRow.length,
      slidesPerView: 'auto',
      spaceBetween: SPACE_BETWEEN
    }
  }

  const getCreatorsData = (creators) => {
    return creators.map(({ node: { id, frontmatter } }) => {
      const {
        title,
        subtitle,
        location,
        position,
        awards,
        image: { publicURL: image }
      } = frontmatter

      return (
        <FeaturedCreatorTemplate
          key={id}
          title={title}
          subtitle={subtitle}
          location={location}
          position={position}
          image={image}
        >
          {awards && (
            <div tw="mt-6">
              <ul tw="flex flex-wrap gap-x-6 gap-y-1">
                {awards.map(
                  ({ awardsname, awardsimage: { publicURL: awardsimage }, awardscount }, index) => (
                    <li key={index} tw="flex items-center gap-2">
                      {awardsimage && (
                        <img src={awardsimage} alt={awardsname} css={featuredCreatorAwardImg} />
                      )}
                      {awardscount && <span tw="text-paragraph-lg">{awardscount}</span>}
                    </li>
                  )
                )}
              </ul>
            </div>
          )}
        </FeaturedCreatorTemplate>
      )
    })
  }

  return (
    <section id={id} css={StyledSection}>
      <Slider settings={swiperSettings('toprow')}>{getCreatorsData(creatorsTopRow)}</Slider>

      <Slider settings={swiperSettings('bottomrow')}>{getCreatorsData(creatorsBottomRow)}</Slider>
    </section>
  )
}

export { FeaturedCreators }
