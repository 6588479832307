import React from 'react'
import tw, { css } from 'twin.macro'
import { colors } from '../../../config/design-system'
import { toRem, h1Styles, h2Styles, mediaQuery } from '../../styles'

const innerSectionStyles = css`
  ${tw`grid grid-cols-12 grid-rows-4`};
`

const firstHeadingStyles = css`
  ${tw`flex flex-col justify-center col-span-12 row-start-3 row-end-5 absolute px-5 font-subheading font-thin text-header2-lg md:col-start-2 md:col-end-12 md:px-0 xl:col-start-4 xl:row-end-4 xl:static xl:font-heading xl:font-medium`};
  max-width: ${toRem(1100)};
  ${mediaQuery(
    `
    padding-left: ${toRem(30)};
  `
  )}
  ${mediaQuery(
    `
    padding-left: ${toRem(40)};
  `,
    'big-desktop'
  )}
  ${mediaQuery(
    `
    max-width: ${toRem(2300)};
    padding-left: ${toRem(120)};
  `,
    '4k'
  )}
`

const secondHeadingStyles = css`
  ${tw`hidden w-full flex-col items-end row-start-4 col-span-12 absolute xl:flex xl:pr-8`};
  font-size: ${toRem(96)};
  bottom: 10%;
  -webkit-text-stroke: 1px ${colors['light-text-color']};
  ${mediaQuery(`
    font-size: 7vw;
  `)}
`

const underlineStyles = css`
  ${tw`absolute left-0`};
`

const headingButtonStyles = css`
  transition: all 0.25 ease-in-out;
  &:hover {
    color: ${colors['light-text-color']};
    -webkit-text-stroke: none;
  }
`

const Hero = ({ handleSetBgVideo, bgVideoTiggers }) => {
  const [defaultTrigger, trigger1, trigger2] = bgVideoTiggers

  return (
    <section css={innerSectionStyles} tw="h-full w-full relative">
      <strong css={[h2Styles, firstHeadingStyles]}>
        <span tw="font-thin font-subheading xl:font-heading xl:font-medium">
          We represent the world's top independent creatives and filmmakers, bridging the gap
          between
        </span>
        <span css={h1Styles} tw="xl:hidden">
          <span tw="relative">
            <span>advertising</span>
            <svg
              width="206"
              height="8"
              viewBox="0 0 206 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              css={[underlineStyles, `transform: translate(3%, 0%);`]}
            >
              <path
                d="M0.0843723 3.49401C64.1956 0.636267 96.3188 0.462138 133.781 1.20698C174.944 2.02539 191.794 5.18963 205.847 7.30584"
                stroke="#E55708"
              />
            </svg>
          </span>
          <span> and </span>
          <span tw="relative">
            <span>entertainment</span>
            <svg
              width="273"
              height="10"
              viewBox="0 0 273 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              css={[underlineStyles, `transform: translate(2%, 0%);`]}
            >
              <path
                d="M272.692 8.79461C187.8 3.01367 145.249 1.70337 95.6137 1.37078C41.0763 1.00534 18.7161 4.42392 0.0743673 6.61571"
                stroke="#E55708"
              />
            </svg>
          </span>
        </span>
      </strong>
      <h2 css={secondHeadingStyles}>
        <button
          tabIndex="0"
          onMouseEnter={() => handleSetBgVideo(trigger1)}
          onMouseLeave={() => handleSetBgVideo(defaultTrigger)}
          tw="relative font-heading uppercase font-semibold text-transparent leading-none"
          css={headingButtonStyles}
        >
          Advertising
        </button>
        <span tw="font-heading uppercase font-semibold text-transparent leading-none">
          &
          <button
            tabIndex="0"
            onMouseEnter={() => handleSetBgVideo(trigger2)}
            onMouseLeave={() => handleSetBgVideo(defaultTrigger)}
            tw="relative font-heading uppercase font-semibold text-transparent leading-none ml-2"
            css={headingButtonStyles}
          >
            entertainment
          </button>
        </span>
      </h2>
    </section>
  )
}

export { Hero }
