import React from 'react'
import tw, { css } from 'twin.macro'
import { toRem, mediaQuery, h2Styles, paragraphStyles } from '../../styles'

const sectionStyles = css`
  ${tw`row-start-2 col-span-12 h-full w-full pl-5 xl:col-start-2 xl:col-end-8 xl:pl-0 xl:flex xl:flex-col xl:justify-center xl:mx-auto xl:p-0`};
  max-width: ${toRem(1000)};
  ${mediaQuery(
    `
    max-width: 60vw;
  `,
    'big-desktop'
  )}
`

const sectionTextStyles = css`
  ${h2Styles}
  max-width: ${toRem(250)};
`

const underlineStyles = css`
  ${tw`absolute left-0`};
`

const firstUnderlineStyles = css`
  ${underlineStyles}
  transform: translate(0, -50%);
  width: ${toRem(180)};
  ${mediaQuery(
    `
    transform: translate(4%, -50%);
    width: 100%;
    height: 100%;
  `,
    'tablet-landscape'
  )}
  ${mediaQuery(
    `
    transform: translate(4%, -50%);
  `,
    'big-desktop'
  )}
`

const secondUnderlineStyles = css`
  ${underlineStyles}
  transform: translate(0, -40%);
  width: ${toRem(164)};
  ${mediaQuery(`
    transform: translate(0, -60%);
    width: 100%;
    height: 100%;
  `)}
`

const thirdUnderlineStyles = css`
  ${underlineStyles}
  width: ${toRem(170)};
  ${mediaQuery(
    `
    transform: translate(0, -60%);
    width: ${toRem(175)};
    height: ${toRem(7)};
  `,
    'tablet-portrait'
  )}
  ${mediaQuery(`
    width: ${toRem(196)};
    height: ${toRem(7)};
    transform: translate(0, -55%);
  `)}
`

const WhyToApply = ({ id }) => {
  return (
    <section
      tw="grid grid-cols-12 grid-rows-6 h-full w-full xl:flex xl:flex-col xl:justify-center xl:items-center"
      id={id}
    >
      <div css={sectionStyles}>
        <h3 css={paragraphStyles} tw="mb-8 font-primary font-thin">
          Dogma connects the best talent to each project:
        </h3>
        <div tw="grid grid-cols-12 gap-y-5 xl:grid-rows-none xl:grid-cols-3 xl:gap-x-10 xl:gap-y-0">
          <p
            css={[
              sectionTextStyles,
              `
                ${mediaQuery(`max-width: ${toRem(236)};`)}
                ${mediaQuery(`max-width: unset;`, 'big-desktop')}
              `
            ]}
            tw="pt-4 pb-12 col-start-1 col-end-8 font-thin sm:col-start-3 xl:col-span-1 xl:py-0"
          >
            <span>Visibility with </span>
            <strong tw="font-medium">
              <span>major </span>
              <span tw="relative">
                <span>advertisers,</span>
                <svg
                  height="9"
                  viewBox="0 0 202 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  css={firstUnderlineStyles}
                >
                  <path
                    d="M0.99976 8.0002C63.2492 2.83815 94.4669 1.54722 130.894 1.01811C170.919 0.436747 187.365 3.11296 201.07 4.80646"
                    stroke="#E55708"
                  />
                </svg>
              </span>
              <span> platforms and channels.</span>
            </strong>
          </p>
          <p
            css={[
              sectionTextStyles,
              `
                ${mediaQuery(`max-width: ${toRem(300)};`)}
                ${mediaQuery(`max-width: unset;`, 'big-desktop')}
              `
            ]}
            tw="pb-12 col-start-5 col-end-13 font-thin sm:col-start-7 xl:col-span-1 xl:pb-0"
          >
            <span>Work on projects </span>
            <strong tw="font-medium">
              <span>you are </span>
              <span tw="relative">
                <span>passionate</span>
                <svg
                  viewBox="0 0 196 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  css={secondUnderlineStyles}
                >
                  <path
                    d="M195.088 6.29251C168.513 4.10227 112.837 1.67175 77.5003 1.5004C38.673 1.31212 14.2747 4.07913 1.0012 5.17501"
                    stroke="#E55708"
                  />
                </svg>
              </span>
              <span> about.</span>
            </strong>
          </p>
          <p
            css={[
              sectionTextStyles,
              `
                max-width: ${toRem(230)};
                ${mediaQuery(`max-width: ${toRem(295)};`)}
                ${mediaQuery(`max-width: unset;`, 'big-desktop')}
            `
            ]}
            tw="font-thin col-start-3 col-end-11 sm:col-start-5 xl:col-span-1"
          >
            <span>Networking: Be part of a </span>
            <strong tw="font-medium">
              <span>global </span>
              <span tw="relative">
                <span>community.</span>
                <svg
                  viewBox="0 0 196 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  css={thirdUnderlineStyles}
                >
                  <path
                    d="M195.088 6.29251C168.513 4.10227 112.837 1.67175 77.5003 1.5004C38.673 1.31212 14.2747 4.07913 1.0012 5.17501"
                    stroke="#E55708"
                  />
                </svg>
              </span>
            </strong>
          </p>
        </div>
      </div>
    </section>
  )
}

export { WhyToApply }
