import React from 'react'
import tw, { css } from 'twin.macro'
import { StyledSection, paragraphStyles } from '../../styles'
import firstHowToApplyImg from '../../assets/creators/how-to-apply_01.jpg'
import secondHowToApplyImg from '../../assets/creators/how-to-apply_02.jpg'
import thirdHowToApplyImg from '../../assets/creators/how-to-apply_03.jpg'
import HowToApplyList from '../HowToApplyList'

const underlineStyles = css`
  ${tw`absolute opacity-0`};
  left: 50%;
  top: 50%;
  transition: opacity 0.2s ease-in-out;
`

const firstUnderlineStyles = css`
  width: 98%;
  transform: translate(-50%, -50%);
`

const secondUnderlineStyles = css`
  width: 113%;
  transform: translate(-50%, -45%) scaleY(0.9);
`

const thirdUnderlineStyles = css`
  width: 100%;
  transform: translate(-50%, -50%);
`

const listItems = [
  {
    id: 1,
    content: () => (
      <>
        <span tw="font-thin">We want to meet you! </span>
        <strong tw="font-medium">
          Tell us about
          <span tw="inline-flex relative ml-2">
            <span>yourself.</span>
            <svg
              viewBox="0 0 155 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              css={[underlineStyles, firstUnderlineStyles]}
            >
              <path
                d="M48.5889 9.31835C80.3834 -0.114318 138.817 -4.85399 153.385 17.6673C163.225 36.6419 83.5117 44.5254 20.6472 31.5212C-42.2174 18.5171 56.7441 -5.52804 121.014 5.71017"
                stroke="#E55708"
              />
            </svg>
          </span>
        </strong>
      </>
    ),
    img: firstHowToApplyImg,
    alt: ''
  },
  {
    id: 2,
    content: () => (
      <>
        <strong tw="font-medium">
          Attach your
          <span tw="inline-flex relative mx-2">
            <span>portfolio</span>
            <svg
              viewBox="0 0 177 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              css={[underlineStyles, secondUnderlineStyles]}
            >
              <path
                d="M121.254 9.16733C85.3327 -0.333429 19.1585 -5.19602 2.36468 17.2988C-9.02573 36.2562 81.2298 44.3076 152.648 31.4325C224.067 18.5575 112.195 -5.69872 39.2059 5.4072"
                stroke="#E55708"
              />
            </svg>
          </span>
        </strong>
        <span tw="font-thin">and links.</span>
      </>
    ),
    img: secondHowToApplyImg,
    alt: ''
  },
  {
    id: 3,
    content: () => (
      <>
        <strong tw="inline-flex relative font-medium mr-2">
          <span>Apply</span>
          <svg
            viewBox="0 0 113 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            css={[underlineStyles, thirdUnderlineStyles]}
          >
            <path
              d="M77.0898 9.69875C54.0042 -0.430597 11.6445 -6.25401 1.2117 16.3671C-5.81485 35.4679 51.9788 44.8519 97.4532 32.7311C142.928 20.6103 71.1013 -5.52034 24.5994 4.78271"
              stroke="#E55708"
            />
          </svg>
        </strong>
        <span tw="font-thin">to The Dogma Community!</span>
      </>
    ),
    img: thirdHowToApplyImg,
    alt: ''
  }
]

const HowToApply = ({ id }) => {
  return (
    <StyledSection tw="min-h-screen flex flex-col justify-center mt-24 lg:mt-0 lg:h-screen" id={id}>
      <p css={paragraphStyles} tw="relative z-10 mb-10">
        Follow these steps and join The Dogma Community:
      </p>
      <HowToApplyList listItems={listItems} />
    </StyledSection>
  )
}

export { HowToApply }
