import React, { useRef, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { useStaticQuery, graphql } from 'gatsby'
import tw, { css } from 'twin.macro'
import { gsap } from 'gsap'
import { parse } from 'marked'
import { toMatrix, windowExists } from '../../utils/ui-utils'
import { toRem, mediaQuery, StyledSection, h1Styles } from '../../styles'
import { buttonStyles } from '../Button'
import Slider from '../Slider'

const GET_TEAM_MEMBERS = graphql`
  query TeamMembersQuery {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: { frontmatter: { templateKey: { eq: "team-member" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            templateKey
            name: title
            position
            image {
              publicURL
            }
          }
        }
      }
    }
  }
`

const gridListItemStyles = css`
  ${tw`grid content-center justify-center place-content-center grid-cols-2`}
  gap: ${toRem(17)};
`

const flexListItemStyles = css`
  ${tw`flex`}
  gap: ${toRem(10)};
`

const teamListStyles = css`
  ${tw`flex flex-wrap justify-center uppercase relative`}
  gap: ${toRem(8)} ${toRem(18)};
`

const teamTitleStyles = css`
  ${tw`self-end justify-self-end place-self-end uppercase font-title font-thin leading-tight z-10`};
  font-size: ${toRem(32)};
  white-space: nowrap;
  ${mediaQuery(
    `
    font-size: ${toRem(45)};
  `,
    'laptop'
  )}
`

const teamPositionStyles = css`
  ${tw`font-team font-thin uppercase leading-tight mt-2 lg:mt-2 z-10`};
  font-size: ${toRem(12)};
  ${mediaQuery(
    `
    font-size: ${toRem(17)};
  `,
    'laptop'
  )}
`

const teamImageStyles = css`
  ${tw`absolute object-cover w-56 opacity-0 z-0 pointer-events-none`}
  width: ${toRem(250)};
  height: ${toRem(350)};
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
  filter: brightness(0.8);
`

const swiperBtn = css`
  ${tw`absolute z-10`};
  top: 50%;
`

const swiperBtnPrev = css`
  left: ${toRem(10)};
`

const swiperBtnNext = css`
  right: ${toRem(10)};
`

const OurTeam = ({ id }) => {
  const data = useStaticQuery(GET_TEAM_MEMBERS)
  const {
    allMarkdownRemark: { edges: teamMembers }
  } = data || {}

  const team2D = toMatrix(teamMembers, 8)
  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)

  const teamImageRef = useRef([])

  const sliderSettings = {
    slidesPerView: 1,
    centeredSlides: true,
    grabCursor: true,
    navigation: {
      prevEl: prevEl,
      nextEl: nextEl
    }
  }

  const handleOnMouseEnter = (e, i) => {
    const targetEl = e.currentTarget
    const teamImageEl = teamImageRef.current[i]
    const mqlMatchesDesktopDown = windowExists && window.matchMedia('(max-width: 1300px)').matches

    const yPos = 200
    const xPos = 370
    const rotatePos = 5

    const imgToLeft = { x: (e.clientX = -xPos), rotate: (e.clientX = rotatePos) }
    const imgToRight = { x: (e.clientX = +xPos), rotate: (e.clientX = -rotatePos) }

    const imageXPosition =
      targetEl.offsetLeft < targetEl.parentElement.clientWidth / 2.5 && !mqlMatchesDesktopDown
        ? imgToLeft
        : imgToRight

    gsap.to(teamImageEl, { autoAlpha: 1 })
    gsap.set(teamImageEl, {
      y: (e.clientY = -yPos),
      ...imageXPosition
    })
  }

  const handleOnMouseLeave = (i) => gsap.to(teamImageRef.current[i], { autoAlpha: 0 })

  return (
    <StyledSection id={id} tw="flex flex-col justify-center">
      <h2 css={h1Styles} tw="lg:text-header1-sm text-center mb-20">
        Dogma Media Team
      </h2>
      <div tw="relative lg:hidden">
        <Slider settings={sliderSettings}>
          {team2D.map((slider) => {
            return (
              <ul key={uuid()}>
                {slider.map(({ node: teamMember }) => {
                  const {
                    id,
                    frontmatter: { name, position }
                  } = teamMember

                  return (
                    <li key={id} css={gridListItemStyles}>
                      <span css={teamTitleStyles}>{name}</span>
                      <span
                        css={teamPositionStyles}
                        dangerouslySetInnerHTML={{ __html: parse(position) }}
                      />
                    </li>
                  )
                })}
              </ul>
            )
          })}
        </Slider>
        <button
          type="button"
          css={[buttonStyles, swiperBtn, swiperBtnPrev]}
          ref={(node) => setPrevEl(node)}
        >
          ←
        </button>
        <button
          type="button"
          css={[buttonStyles, swiperBtn, swiperBtnNext]}
          ref={(node) => setNextEl(node)}
        >
          →
        </button>
      </div>
      <div tw="hidden lg:flex">
        <ul css={teamListStyles}>
          {teamMembers.map(({ node: teamMember }, i) => {
            const {
              id,
              frontmatter: {
                name,
                position,
                image: { publicURL: imageSrc }
              }
            } = teamMember

            return (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                key={id}
                css={flexListItemStyles}
                onMouseEnter={(e) => handleOnMouseEnter(e, i)}
                onMouseLeave={() => handleOnMouseLeave(i)}
              >
                <img
                  ref={(ref) => (teamImageRef.current[i] = ref)}
                  css={teamImageStyles}
                  src={imageSrc}
                  alt={name}
                />
                <span css={teamTitleStyles}>{name}</span>
                <span
                  css={teamPositionStyles}
                  dangerouslySetInnerHTML={{ __html: parse(position) }}
                />
              </li>
            )
          })}
        </ul>
      </div>
    </StyledSection>
  )
}

export { OurTeam }
