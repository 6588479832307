import React, { useState } from 'react'
import { styled } from 'twin.macro'
import { Tween, ScrollTrigger } from 'react-gsap'
import Layout from '../components/Layout'
import Header from '../components/Header'
import AnchorMenu from '../components/AnchorMenu'
import Footer from '../components/Footer'
import {
  PinnedSections,
  HowToApply,
  OurTeam,
  Portfolio,
  FeaturedCreators
} from '../components/creators'
import { OurCommunity } from '../components/brands'
import Menu from '../components/Menu'
import { mediaQuery } from '../styles'

const StyledSectionContainer = styled.div`
  overflow-x: ${({ xVisible }) => (xVisible ? 'visible' : 'hidden')};
  overflow-y: ${({ yHidden }) => yHidden && 'hidden'};

  ${mediaQuery(`
   overflow-y: unset;
  `)}
`

const Creators = ({ location }) => {
  const sections = [
    {
      id: 'why-to-apply',
      title: 'Why to apply',
      start: 'top 50%',
      component: (props) => <PinnedSections {...props} />
    },
    {
      id: 'how-to-apply',
      title: 'How to apply',
      start: 'top 50%',
      component: (props) => <HowToApply {...props} />
    },
    {
      id: 'our-community',
      title: 'Our community',
      start: 'top 50%',
      component: (props) => <OurCommunity {...props} withPlatforms />
    },
    {
      id: 'featured-creators',
      title: 'Featured creators',
      start: 'top 50%',
      component: (props) => <FeaturedCreators {...props} />
    },
    {
      id: 'portfolio',
      title: 'Portfolio',
      start: 'top 50%',
      component: (props) => <Portfolio {...props} />
    },
    {
      id: 'our-team',
      title: 'Our team',
      start: 'top 50%',
      component: (props) => <OurTeam {...props} />
    }
  ]

  const defaultSectionsState = sections.reduce((acc, { id }) => {
    acc[id] = false
    return acc
  }, {})

  const anchorMenuLinks = sections.map(({ id, title }) => ({ id, title }))

  const [sectionsState, setSectionsState] = useState(defaultSectionsState)
  const [showFullMenu, setShowFullMenu] = useState(false)

  const handleMenuAction = () => {
    setShowFullMenu((menuState) => !menuState)
  }

  return (
    <Layout
      title="Creators"
      description="We represent the world's top independent creatives and filmmakers, bridging the gap between advertising and entertainment"
    >
      <Header menuAction={handleMenuAction} />
      <main tw="relative">
        {sections.map(({ id, start, component }) => (
          <React.Fragment key={id}>
            <ScrollTrigger
              start={start}
              end="bottom 50%"
              trigger={`#${id}`}
              onToggle={() =>
                setSectionsState((sectionsState) => ({
                  ...sectionsState,
                  [id]: !sectionsState[id]
                }))
              }
            >
              <Tween>
                <StyledSectionContainer
                  xVisible={id === 'portfolio'}
                  yHidden={id === 'how-to-apply'}
                >
                  {component({ id })}
                </StyledSectionContainer>
              </Tween>
            </ScrollTrigger>
          </React.Fragment>
        ))}
        <AnchorMenu
          startSectionId="why-to-apply"
          links={anchorMenuLinks}
          sectionsState={sectionsState}
        />
      </main>
      <Footer />
      <Menu show={showFullMenu} closeMenu={handleMenuAction} location={location} />
    </Layout>
  )
}

export default Creators
